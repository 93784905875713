import { default as React } from "react"
import { ThemeProvider } from "styled-components"
import { navigate } from "gatsby";

const theme = {
  foreground: "#050505",
  background: "white",
  faded: "#888",
}

export default function Search() {

  return (
    <ThemeProvider theme={theme}>

    <form 
      name="searchForm"
      className="bg-transparent" 
      onSubmit={(event) => {
            event.preventDefault();
            navigate(`/search/?query=${encodeURIComponent(window.searchForm.query.value)}`);
    }}>
      <input className="search-box rounded-full p-1 border-none px-2 text-xs focus:ring-0" type="text" placeholder="Search" ariaLabel="Search" name="query" />
      <button type="submit" class="search-box-button mx-2 my-1">
        <img alt="search icon" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMy4yNSIgaGVpZ2h0PSIyMy4yNSIgdmlld0JveD0iMCAwIDIzLjI1IDIzLjI1Ij4KICA8cGF0aCBpZD0iUGF0aF8yNTQiIGRhdGEtbmFtZT0iUGF0aCAyNTQiIGQ9Ik0tOTQuNzUtNTYuNDE4bC00LjM0NC00LjM0NGExMC41NTcsMTAuNTU3LDAsMCwwLDIuMzMtNi42MkExMC42MzEsMTAuNjMxLDAsMCwwLTEwNy4zODItNzgsMTAuNjMxLDEwLjYzMSwwLDAsMC0xMTgtNjcuMzgyYTEwLjYzMSwxMC42MzEsMCwwLDAsMTAuNjE4LDEwLjYxOCwxMC41NTcsMTAuNTU3LDAsMCwwLDYuNjItMi4zM2w0LjM0NCw0LjM0NFptLTIwLjg5LTEwLjk2NGE4LjI2OCw4LjI2OCwwLDAsMSw4LjI1OC04LjI1OCw4LjI2OCw4LjI2OCwwLDAsMSw4LjI1OCw4LjI1OCw4LjI2OCw4LjI2OCwwLDAsMS04LjI1OCw4LjI1OEE4LjI2OCw4LjI2OCwwLDAsMS0xMTUuNjQtNjcuMzgyWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTE4IDc4KSIgLz4KPC9zdmc+Cg=="/>
      </button>
    </form>
    </ThemeProvider>
  )
}