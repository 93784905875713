import React from "react";
import { getGatsbyImageData } from '@imgix/gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// Assume a Focal Point has been set, it is the responsibility of the parent to tell us differently
const ImgixFluidImage = ({ imageUrl, ar = 1.33333, focalPoint : [focalPointX, focalPointY], sizes = "100vw", hasFocalPoint = true, fit = 'crop', alt="image", attribution, className = "",}) => {
  
  const imgixParams = {
    ar: ar, 
    auto: ['format', 'compress'], 
    crop: 'focalpoint',
    fit: fit,
     // If focal point is not set in the CMS, default to top of image (to prevent head cropping)
    'fp-x': hasFocalPoint ? focalPointX : 0.5,
    'fp-y': hasFocalPoint ? focalPointY : 0, 
    'fp-z': 1
  }

  if (fit==="fill") imgixParams["fill"] = 'blur';

  const gatsbyImageData = getGatsbyImageData({
    src: imageUrl,
    imgixParams: imgixParams,
    layout: "constrained",
    aspectRatio: ar,
    sizes: sizes
  });

  return (
    <div className="relative">
      <GatsbyImage className={className} image={gatsbyImageData} alt={alt}/>
      {attribution ?  <span className="absolute bottom-2 right-1 opacity-50" style={{fontSize: "10px"}}>{attribution}</span> : <></>}
    </div>
  );
    
  
};

export default ImgixFluidImage;
