import React, { useState, useEffect } from "react";

const CookieNotice = () => {

  const [acceptance, setAcceptance] = useState('true');
  useEffect(() => { setAcceptance(localStorage.getItem('cookie_acceptance') || 'false'); }, []);

  return (
    acceptance === 'true' ?
      <></>
      :
      <div id="cookie_notice" style={{ "-webkit-text-align": "center", "position": "fixed", "inset": "auto 0px 0px", "padding-top": "1rem", "padding-left": "1rem", "padding-right": "1rem", "z-index": "9999", "background-image": "initial !important", "background-position": "initial !important", "background-size": "initial !important", "background-repeat": "initial !important", "background-attachment": "initial !important", "background-origin": "initial !important", "background-clip": "initial !important", "background-color": "rgb(114, 168, 78)", "color": "white !important" }}>
        <div className="shell">
          <p className="section__title h4" style={{"color": "white", "margin-bottom": "0.25rem"}}>This website uses cookies</p>
          <p className="section__subtitle" style={{"color": "white", "margin-bottom": "1rem"}}>We use cookies to improve your experience and analyse the traffic on our website, but we are fully committed to respecting your privacy</p>
          <button id="acceptButton" className="btn btn--white-green" style={{"margin-right": "2rem", "margin-bottom": "1rem"}} onClick={() => { localStorage.setItem('cookie_acceptance', true); setAcceptance(localStorage.getItem('cookie_acceptance')); }}>Accept</button>
          <a style={{"color": "white", "margin-right": "2rem", "margin-bottom": "1rem", "text-decoration": "none"}} href="https://www.google.com" target="_blank" rel="noreferrer">Deny</a>
          <a style={{"color": "white", "margin-bottom": "1rem", "text-decoration": "none"}} href="/cookie-policy" target="_blank" rel="noreferrer">View Cookie Policy</a>
        </div>
      </div>
  )
}

export default CookieNotice;
