import { Modal, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import React from "react";
// import SubscribeForm from "./subscribeForm";

function SectionCta() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
    <section id="signUpBanner" className="section-cta">
    <div className="shell">
      <p className="section__title h4">Sign up to Genesis News</p>
      <p className="section__subtitle">for the latest news and information</p>

      <button onClick={onOpen} className="btn btn--white-green js-form-open">Sign up</button>


      {/* <Button onClick={onOpen} className={`btn btn--white-green js-form-open`} borderRadius="md">Sign up</Button> */}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          {/* <ModalCloseButton /> */}
          {/* <ModalBody> */}
          <iframe src="https://flowcommunications.formstack.com/forms/news_signup_form" title="News Sign Up Form" width="100%" height="640"></iframe>
          {/* </ModalBody> */}

          {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </div>
    </section>
    </>
  )
}



export default SectionCta;
