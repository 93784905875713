import { graphql, useStaticQuery } from "gatsby"
import React, {useState} from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { navLinks } from "../utils/links";

const NavLinks = styled.ul`
  ${tw`lg:flex lg:-mx-3`}
  [aria-current="page"] {
    ${tw`text-yellow-golden font-extrabold`}
  }
`;

const NavbarLinks = ({pageContext }) => {

  const activePath = pageContext !== undefined ? (pageContext.current === "/pages" ? "/"+pageContext.slug : ( pageContext.slug !== undefined ? (pageContext.current.includes('/') ? pageContext.current.replace('/','') :pageContext.current)+"/"+pageContext.slug : pageContext.current)): "/"
  const currentClean = activePath.replace(/\/$/, "");
  const data = useStaticQuery(graphql`
    query {
      links: allCraftMainEntryLinkEntry(sort: {fields: lft}) {
        nodes {
          title
          linkUrl
          entry {
            uri
          }
          level
          remoteParent {
            remoteId
          }
          remoteId
        }
      }
    }
  `);

  const links = navLinks(data.links.nodes, currentClean);

  const [expanded, setExpanded] = useState({});

  function doSetExpanded (link) {
    expanded[link.remoteId] = !expanded[link.remoteId];
    let expanded2 = {...expanded};
    setExpanded(expanded2);
  }
  
  return (
    <>
      <NavLinks>
        {links.map((link, i) => {

          let itemlink = link.children && link.children.length > 0 ? null : (link.linkUrl ? link.linkUrl: (link.entry.length > 0 ? "/" +link.entry[0].uri : null));

          const dropdownClasses = link.children && link.children.length > 0 ? "hoverable has-dropdown mainLi" : "hoverable mainLi";
          
          return (
            <li className={dropdownClasses} key={link.title}>
              <a onClick={() => doSetExpanded(link)} className={`relative p-4 underline-none hover:text-red-600 border-0 border-b ${link.active ? 'active' : "" }`} href={itemlink}>{link.title}</a>
              {link.children && link.children.length > 0 && link.title === "What we do" ? (
                <div className="sm:mb-0 bg-white">
                  <div className="subNav">
                    <ul className={expanded[link.remoteId] ? 'block' : 'hidden'} aria-label="submenu">
                      {link.children.map((subLink) => (
                        <li className={`border-0${subLink.children && subLink.children.length > 0 ? ' has-dropdown' : ""}`} key={subLink.title}>
                          <a className={`p-4 border-b underline-none hover:text-red-600 text-md font-bold${subLink.active ? " active" : "" }`} style={{fontWeight: "700"}} href={subLink.entry.length > 0 ?  "/" +subLink.entry[0].uri : (subLink.linkUrl ? subLink.linkUrl : null)}>{subLink.title}</a>
                          {subLink.children && subLink.children.length > 0 ? (
                            <ul className="text-gray-3 pl-4" aria-label="submenu">
                              {subLink.children.map((subsubChild) => (
                                <li key={subsubChild.title}>
                                  <a className={`p-4 border-b underline-none hover:text-red-600 text-md  ${subsubChild.active ? 'active' : "" }`} href={subsubChild.entry.length > 0 ? "/" +subsubChild.entry[0].uri : (subsubChild.linkUrl ? subsubChild.linkUrl : null)}>{subsubChild.title}</a>
                                </li>
                              ))}
                            </ul>
                          ): null}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ): null }
              {link.children && link.children.length > 0 && link.title !== "What we do" ? (
                <div className="bg-white">
                  <ul className={expanded[link.remoteId] ? 'block' : 'hidden'} aria-label="submenu">
                    {link.children.map((subLink) => (
                      <li className="px-8 py-4 border-b" key={subLink.title}>
                        <a className={`underline-none hover:text-red-600 text-md  ${subLink.active ? 'active' : null }`} href={subLink.entry.length > 0 ?  "/" +subLink.entry[0].uri : (subLink.linkUrl ? subLink.linkUrl : null)}>{subLink.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              ): null }
            </li>
          )
        })}
      </NavLinks>
    </>
  )
}

export default NavbarLinks
