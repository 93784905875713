/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Footer from "./Footer";
import Header from "./header";
import SectionCta from "./newComponents/sectionCta";
import CookieNotice from "./CookieNotice"

import "@fontsource/lato/300.css"
import "@fontsource/lato/400.css"
import "@fontsource/lato/700.css"
import "@fontsource/lato/900.css"

import "@fontsource/roboto/900.css"
import "@fontsource/roboto/700.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/300.css"

import "../styles/main.scss"

const Layout = ({ children, sidebar, pageContext, customNavLogo, customClassName }) => {

  const currentHostname = typeof window !== `undefined` ? window.location.hostname : "nohostname";

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={`h-full flex flex-col font-body antialiased font-normal ${sidebar ? " overflow-x-hidden" : null} ${customClassName}`}>
      <Helmet>
        <meta content="IE=edge" http-equiv="X-UA-Compatible"/>
        <meta charset="utf-8"/>
        <title>{data.site.siteMetadata.title}</title> 
        <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport"/>
        <meta content="origin-when-cross-origin" name="referrer" />
        <link rel="apple-touch-icon"sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <meta name="google-site-verification" content="bahRh2WONGy1i4_g9R6F_Wy0g3SJRea2l4eaNU9xXd8"/>
        {currentHostname === "localhost" || currentHostname === "genesis2.gatsbyjs.io" || currentHostname === "genesis2.gtsb.io" || currentHostname === "preview-genesis2.gtsb.io" || currentHostname ==="test.genesis-analytics.com" ? <meta name="robots" content="noindex"/> : <meta/>}
      </Helmet>
      <div>
        <Header pageContext={pageContext} customNavLogo={customNavLogo}/>
        <div className="wrapper">
          <div className="wrapper__inner">        
            <div className="overlay js-overlay" />
            {children}
            <SectionCta/>  
            <Footer/>
          </div>
        </div>
        <CookieNotice/>
      </div>
    </div>
  )
}

export default Layout;