import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { navLinks } from "../utils/links";

const NavLinks = styled.ul`
  ${tw`lg:flex lg:-mx-3`}
  [aria-current="page"] {
    ${tw`text-yellow-golden font-extrabold`}
  }
`;

const NavbarLinks = ({pageContext }) => {

  const activePath = pageContext !== undefined ? (pageContext.current === "/pages" ? "/"+pageContext.slug : ( pageContext.slug !== undefined ? (pageContext.current.includes('/') ? pageContext.current.replace('/','') :pageContext.current)+"/"+pageContext.slug : pageContext.current)): "/"
  const currentClean = activePath.replace(/\/$/, "");
  const data = useStaticQuery(graphql`
    query {
      links: allCraftMainEntryLinkEntry(sort: {fields: lft}) {
        nodes {
          title
          linkUrl
          entry {
            uri
          }
          level
          remoteParent {
            remoteId
          }
          remoteId
        }
      }
    }
  `);

  const links = navLinks(data.links.nodes, currentClean);

  return (
    <NavLinks>
      {links.map((link) => {

        let itemlink = link.children && link.children.length > 0 ? null : (link.linkUrl ? link.linkUrl: (link.entry.length > 0 ? "/" +link.entry[0].uri : null));
        const dropdownClasses = link.children && link.children.length > 0 ? "hoverable pr-8 has-dropdown mainLi navMainArrow" : "hoverable pr-4 mainLi";

        return (
          <li className={dropdownClasses} key={link.title}>
            <a className={`relative underline-none hover:text-red-600 ${link.active ? 'active' : "" }`} href={itemlink}>{link.title}</a>
            {link.children && link.children.length > 0 && link.title === "What we do" ?
              <div className=" mega-menu mb-16 pt-6 sm:mb-0 shadow-xl">
                <div className="bg-white">
                  <div className="shell py-5 border-t-8 border-red-600 mx-auto w-full flex flex-col flex-wrap justify-between">
                    <div className="subNav">
                      <ul aria-label="submenu">
                        {link.children.map((subLink) => (
                          <li className={` flex flex-col mb-4 ${subLink.children && subLink.children.length > 0 ? 'hasDropdown' :""}`} key={subLink.title}>
                            <a className={` underline-none ${subLink.title == "Services" ? "red-override" : ""} hover:text-red-600 text-md font-bold ${subLink.active ? 'active' : "" }`} href={subLink.entry.length > 0 ?  "/" +subLink.entry[0].uri : (subLink.linkUrl ? subLink.linkUrl : null)}>{subLink.title}</a>
                            {subLink.children && subLink.children.length > 0 ?
                              <ul className="flex flex-wrap flex-col text-gray-3 ml-2 pt-3" aria-label="submenu">
                                {subLink.children.map((subsubChild) => (
                                  <li className="pr-1 mb-1" key={subsubChild.title}>
                                    <a className={`underline-none hover:text-red-600 text-md  ${subsubChild.active ? 'active' : "" }`} href={subsubChild.entry.length > 0 ? "/" +subsubChild.entry[0].uri : (subsubChild.linkUrl ? subsubChild.linkUrl : null)}>{subsubChild.title}</a>
                                  </li>
                                ))}
                              </ul>
                              : 
                              null
                            }
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>  
              </div>
              : 
              null
            }
            {link.children && link.children.length > 0 && link.title !== "What we do" ? 
              <div className="normal-menu border shadow  p-4 mb-16  sm:mb-0 bg-white shadow-xl">
                <ul className="flex flex-col" aria-label="submenu">
                  {link.children.map((subLink) => (
                    <li  className="mb-2" key={subLink.title}>
                      <a className={`underline-none hover:text-red-600 text-md  ${subLink.active ? 'active' : null }`} href={subLink.entry.length > 0 ?  "/" +subLink.entry[0].uri : (subLink.linkUrl ? subLink.linkUrl : null)}>{subLink.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
              : 
              null 
            }
          </li>
        );
      })}
    </NavLinks>
  );
}

export default NavbarLinks;
