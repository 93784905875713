import React from "react";
import icoYoutubeWhite from "../images/ico-youtube-white.svg";
import icoTwitterWhite from "../images/ico-twitter-white.svg";
import icoInstagramWhite from "../images/ico-instagram-white.svg";
import icoLinkedinWhite from "../images/ico-linkedin-white.svg";
import icoFacebookWhite from "../images/ico-facebook-white.svg";
import closeIcon from "../images/ico-close.svg";
import logo from "../images/logo-new.png";
import Nav from "../components/nav";
import Search from "../components/search/index";
import NavbarLinksMobile from "./NavbarLinksMobile";
import ImgixFluidImage from "./newComponents/ImgixFluidImage";

const Header = ({pageContext, customNavLogo, }) => ( 
  <header className="header">
    <div className="header__top">
      <div className="shell">
        <div className="header__top-inner">
          <div className="socials socials--white">
            <ul>
              <li>
                <a href="https://www.youtube.com/channel/UCZuVOqAUfKvXTPfjBLM7geQ/featured" target="_blank" rel="noreferrer">
                  <img alt="Youtube Icon" src={icoYoutubeWhite} width="18" height="18" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/GenesisEconomic" target="_blank" rel="noreferrer">
                  <img alt="Twitter icon" src={icoTwitterWhite} width="18" height="18" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/genesis_analytics" target="_blank" rel="noreferrer">
                  <img alt="Instagram icon" src={icoInstagramWhite} width="18" height="18" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/genesis-analytics/" target="_blank" rel="noreferrer">
                  <img alt="LinkedIn icon" src={icoLinkedinWhite} width="18" height="18" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/Genesis-Analytics-655374244565943" target="_blank" rel="noreferrer">
                  <img alt="Facebook icon" src={icoFacebookWhite} width="18" height="18"/>
                </a>
              </li>
            </ul>
          </div>
          <div className="search js-search">
            <Search />
          </div>
        </div>
      </div>
    </div>
    <div className="header__bottom bg-white">
      <div className="shell">
        <div className="header__bottom-inner">
            {customNavLogo ?
            <div className="logo" style={{maxWidth: "170px"}}>
                <ImgixFluidImage attribution={customNavLogo.attribution} imageUrl={customNavLogo.url} sizes={"px"} ar={customNavLogo.width / customNavLogo.height} focalPoint={customNavLogo.focalPoint} />
            </div>
              : 
              <a href="/" className="logo">
                <img alt="Logo " src={logo} width="170" height="39" /> 
              </a>
            }
          <nav className="nav">
            <ul>
              <Nav pageContext={pageContext} />
            </ul>
          </nav>
          <span href="/" className="nav-trigger js-nav-trigger z-10" id="mobileNavToggle">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
      </div>
    </div>
    <div className="header__mobile">
      <div className="header__mobile-inner pt-8">
        <nav className="nav">
          <ul>
            <NavbarLinksMobile pageContext={pageContext}/>
          </ul>
        </nav>
        <div className="socials">
          <ul>
            <li>
              <a href="https://www.youtube.com/channel/UCZuVOqAUfKvXTPfjBLM7geQ/featured" target="_blank" rel="noreferrer">
                <img alt="Youtube icon" src={icoYoutubeWhite} width="18" height="18" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/GenesisEconomic" target="_blank" rel="noreferrer">
                <img alt="Twitter icon" src={icoTwitterWhite} width="18" height="18" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/genesis_analytics" target="_blank" rel="noreferrer">
                <img alt="Instagram icon" src={icoInstagramWhite} width="18" height="18"/>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/genesis-analytics/" target="_blank" rel="noreferrer">
                <img alt="Linked In Icon" src={icoLinkedinWhite} width="18" height="18"/>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/Genesis-Analytics-655374244565943" target="_blank" rel="noreferrer">
                <img alt="Facebook icon" src={icoFacebookWhite} width="18" height="18" />
              </a>
            </li>
          </ul>
        </div>
        <button className="nav-close js-nav-trigger" id="mobileNavToggleClose">
          <img alt="" src={closeIcon} width="18" height="18" />
        </button>
      </div>
    </div>
  </header>
);

export default Header;