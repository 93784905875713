const linkModel = (node, activePath = '') => {
    const e = node.entry[0];
    const url = e ? `${(e.uri !== '__home__') ? e.uri : ''}` : node.linkUrl
    const matches = url ? url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i) : false
    node.uri = matches === null ? (url.includes('/') ? url : `/${url}`) : ""
    node.active = node.uri ? (matches !== true && node.uri === activePath) : false
    return node
};

const getHostnameFromRegex = (url) => {
    // run against regex
    const matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
    // extract hostname (will be null if no match is found)
    return matches && matches[1];
}

const navLinks = (items, activePath = '') => {
    const nodes = items.map(node => linkModel(node, activePath));

    const links = nodes.filter(n => n.level === 1).map(node => {
        const link = node;
        const children = nodes.filter(n => n.remoteParent && n.remoteParent.remoteId === node.remoteId)
        link.active = (node.active || children.filter(n => n.active).length)

        nodes.filter(j => j.level === 2).map(node => {
            const link = node;
            const children = nodes.filter(j => j.remoteParent && j.remoteParent.remoteId === node.remoteId)
            link.active = (node.active || children.filter(j => j.active).length)
            link.children = children
            return null;
        })
        link.children = children

        return link
    })

    return links
}

  export { linkModel, getHostnameFromRegex, navLinks };