import React, {useState} from "react"
import icoToTop from "../../images/ico-to-top.svg";

function ScrollToTop() {

  const [showScroll, setShowScroll] = useState(false);

  const scrollTop = () => {
    if (typeof window !== `undefined`) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }
  };

  if (typeof window !== `undefined`) {
    window.addEventListener('scroll', checkScrollTop);
  }

  return (
    <a onClick={scrollTop} onKeyDown={scrollTop} href="#top" className="fixed bottom-0 right-0 w-8 h-8 m-8 z-40" style={{filter: "drop-shadow( 3px 3px 5px rgba(0, 0, 0, .9))", display: showScroll ? 'flex' : 'none'}}>
      {/* back to top */}
    <img
      alt="scroll to top icon"
      className="scrollTop"
      src={icoToTop}
      style={{ display: "flex"}}
    />
    </a>
  )
}

export default ScrollToTop;
