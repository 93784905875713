import React from "react"
import logoFooter from "../images/logo-footer.svg"
import icoYoutubeWhite from "../images/ico-youtube-white.svg"
import icoTwitterWhite from "../images/ico-twitter-white.svg"
import icoInstagramWhite from "../images/ico-instagram-white.svg"
import icoLinkedinWhite from "../images/ico-linkedin-white.svg"
import icoFacebookWhite from "../images/ico-facebook-white.svg"
import { Link } from "gatsby"
import ScrollToTop from "./newComponents/scrollToTop"

const Footer = () => {
  // This won't deploy automatically in the new year - unless I trigger a manual rebuild
  //, so need a better solution, but have 12 months to find it

  const copyrightYear = new Date().getFullYear()

  return (
    <footer className="footer">
      <div className="shell">
        <div className="footer__inner">
          <div className="footer__content">
            <a href="/" className="logo logo--md">
              <img alt="" src={logoFooter} width="213" height="48" />
            </a>
            <p className="footer__credits">
              © {copyrightYear}. All rights reserved.
            </p>
            <div className="socials socials--white">
              <ul>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCZuVOqAUfKvXTPfjBLM7geQ/featured"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="Youtube Icon" src={icoYoutubeWhite} width="18" height="18" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/GenesisEconomic"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="Twitter icon" src={icoTwitterWhite} width="18" height="18" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/genesis_analytics"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="Instagram icon" src={icoInstagramWhite} width="18" height="18" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/genesis-analytics/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="LinkedIn icon" src={icoLinkedinWhite} width="18" height="18" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/Genesis-Analytics-655374244565943"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="Facebook icon" src={icoFacebookWhite} width="18" height="18" />
                  </a>
                </li>
              </ul>
            </div>
            <nav className="footer__nav">
              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/email-disclaimer">Email Disclaimer</Link>
                </li>
                <li>
                  <Link to="/whistle-blowing-policy">Whistle-Blowing Form</Link>
                </li>
              </ul>
            </nav>
          </div>
          <aside className="footer__aside">
            <div className="footer__entry richtext-entry">
              <p>
                Head Office:
                <br />
                50 6th Road, Hyde Park, Johannesburg, 2196
                <br />
                <br />
                Switchboard:
                <br />
                <a href="tel:+270119947000">+27 (0)11 9947 000</a>
                <br />
                <br />
                Facsimile:
                <br />
                <a href="tel:+0866882247">086 688 2247</a>
              </p>
            </div>
            <button className="scroll-top js-scroll-top">
              <ScrollToTop />
            </button>
          </aside>

        </div>
      </div>
    </footer>
  )
}

export default Footer
