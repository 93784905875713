import React from "react"
import NavbarLinks from "./NavbarLinks"

// import styled from "styled-components"
// import tw from "twin.macro"


// const Toggle = styled.div`
//   height: 100%;
//   cursor: pointer;
//   ${tw`py-4 ml-3 flex lg:hidden`}
// `

// const Navbox = styled.div`
//   ${tw`bg-violet flex-col justify-center text-center w-full fixed h-full flex top-0`}
//   ${tw`transition-all duration-300 ease-in`}
//   ${tw`lg:static lg:justify-end lg:text-left lg:flex lg:top-auto`}
//   ${tw`lg:transition-none`}
//   left: ${props => (props.open ? "-100%" : "0")};
//   z-index: ${props => (props.open ? "0" : "1")};

// `

// const Hamburger = styled.div`
//   width: 30px;
//   height: 3px;
//   transition: all .3s linear;
//   align-self: center;
//   position: ${props => (props.open ? "fixed" : "relative")};
//   top: ${props => (props.open ? "1.5rem" : "inherit")};
//   right: ${props => (props.open ? "1rem" : "inherit")};
//   ${tw`z-10 bg-white`}
//   transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};
//   transition-delay: 0, 0.35s;
//   transition-property: transform, top;
  
//   ::before,
//   ::after {
//     width: 30px;
//     height: 3px;
//     ${tw`bg-white`}
//     content: "";
//     position: absolute;
//     transition: all 0.3s linear;
//   }

//   ::before {
//     transform: ${props =>
//       props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
//     top: -10px;
//   }

//   ::after {
//     opacity: ${props => (props.open ? "0" : "1")};
//     transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
//     top: 10px;
//   }
// `

const Nav = ({pageContext }) => {
  // const [navbarOpen, setNavbarOpen] = useState(false)
  return (
      <NavbarLinks pageContext={pageContext}></NavbarLinks>
    /*<nav className="bg-violet z-50" role="navigation" aria-label="Main">
      <div className="w-full sm:container mx-auto px-4">
        <div className="flex items-center justify-start lg:justify-between flex-row-reverse lg:flex-row">
          <Toggle
            navbarOpen={navbarOpen}
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            {navbarOpen ? <Hamburger open /> : <Hamburger />}
          </Toggle>
          {navbarOpen ? (
            <Navbox>
              <NavbarLinks />
            </Navbox>
          ) : (
            <Navbox open>
              <NavbarLinks />
            </Navbox>
          )}
          <div className="search self-stretch">
            <Search indices={searchIndices} className="h-full flex" />
          </div>
        </div>
      </div>
    </nav>*/
  )
}

export default Nav
